<template>
  <div>
    <v-row>
      <v-col sm="10">
        <Heading title="Согласовывающие" />
      </v-col>
      <v-col
        align="right"
        align-self="center"
      >
        <v-btn
          color="blue"
          outlined
          @click="onAddClick"
        >
          Добавить
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="users"
      class="elevation-1"
      :footer-props="{ itemsPerPageOptions: [5, 10, 20, 50] }"
      :items-per-page="20"
      :loading="!isLoaded"
    >
      <template #[`item.districts`]="{ item }">
        {{ listDistricts(item.districts) }}
      </template>

      <template #[`item.actions`]="{ item }">
        <v-btn
          icon
          color="warning"
          @click="onEditClick(item.id)"
        >
          <v-icon>
            mdi-pencil
          </v-icon>
        </v-btn>

        <v-btn
          icon
          color="error"
          @click="onDeleteClick(item.id)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <DeleteConfirmation
      :visible="deleteConfirmationVisible"
      @confirm="deleteConfirm"
      @decline="deleteDecline"
    />

    <CompanyModal
      :user="modalItem"
      @close="onModalClose"
    />
  </div>
</template>

<script>
import { UsersService } from "@/common/api.service"
import DeleteConfirmation from "@/components/Modals/DeleteConfirmation"
import CompanyModal from "@/views/Director/Users/CoordinatorModal"

export default {
  components: {
    DeleteConfirmation,
    CompanyModal
  },

  data() {
    return {
      users: [],
      isLoaded: false,
      toEditId: null,
      toDeleteId: null,
      deleteConfirmationVisible: false,

      headers: [
        {
          text: 'Районы',
          value: 'districts',
          align: 'left',
          sortable: false
        },
        {
          text: 'Телефон',
          value: 'phone',
          sortable: false
        },
        {
          text: 'Телефон ВДГО',
          value: 'service_phone',
          sortable: false
        },
        {
          text: 'E-mail',
          value: 'email',
          sortable: false
        },
        {
          text: 'ФИО',
          value: 'name',
          sortable: false
        },
        {
          text: '',
          value: 'actions',
          align: 'right',
          sortable: false
        }
      ]
    }
  },

  methods: {
    listDistricts (districts) {
      return (districts.map((item) => {
        return item.name
      })).join(', ')
    },

    onAddClick () {
      this.toEditId = 0
    },

    onEditClick (id) {
      this.toEditId = id
    },

    onDeleteClick (id) {
      this.toDeleteId = id
      this.deleteConfirmationVisible = true
    },

    deleteConfirm () {
      this.deleteConfirmationVisible = false
      UsersService.delete(this.toDeleteId).then(() => this.fetchUsers())
    },

    deleteDecline () {
      this.deleteConfirmationVisible = false
    },

    onModalClose (needRefresh) {
      this.toEditId = null

      if (needRefresh) {
        this.users = []
        this.isLoaded = false
        this.fetchUsers()
      }
    },

    fetchUsers () {
      UsersService
        .coordinators()
        .then(({ data }) => {
          this.users = data.users
          this.isLoaded = true
        })
    }
  },

  computed: {
    modalItem () {
      return this.toEditId !== 0 ? this.users.find(user => user.id === this.toEditId) : {}
    }
  },

  beforeMount () {
    this.fetchUsers()
  }
}
</script>
